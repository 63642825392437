import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { shallowEqual, useToggle } from '@beewise/react-utils';
import { useSelector } from 'react-redux';
import { convertArea, getPlural, measureUnits } from 'utils/isIsraeliRanch';
import RanchDetails from 'components/reusables/RightPanel/RanchDetails';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getTotalDeliveredHives, getDeliveryDetails } from './utils';
import { getRanchBroker } from '../../selectors';

import './RanchInfo.scss';

const RanchInfo = ({ ranch, bhomesAmount, startDate, endDate }) => {
    const [showDetails, toggleShowDetails] = useToggle(false);
    const broker = useSelector(getRanchBroker, shallowEqual);

    if (!ranch) {
        return null;
    }

    return (
        <div className="ranch-info">
            {!!bhomesAmount && (
                <p className="text bold">
                    {bhomesAmount} {getPlural('Beehome', bhomesAmount)}{' '}
                    {ranch.totalPlacedStandardHives
                        ? `| ${ranch.totalPlacedStandardHives} Standard
                    ${getPlural('hive', ranch.totalPlacedStandardHives)} `
                        : ''}
                    | {convertArea(ranch?.totalArea, ranch?.country).toFixed()} {measureUnits[ranch?.country]?.area}
                </p>
            )}
            {!!broker && (
                <div className="contact">
                    <div className="text">
                        Contact for support: <span className="bold">{broker.username}</span> | Phone:{' '}
                        <span className="bold">+{broker.phone}</span> | Email:{' '}
                        <a href={`mailto:${broker.email}`} className="bold">
                            {broker.email}
                        </a>
                    </div>
                </div>
            )}
            <div className="text">
                Estimated dates in ranch: <span className="bold">{dayjs(startDate).format('MM/DD/YY')}</span>
                {' - '}
                <span className="bold">{dayjs(endDate).format('MM/DD/YY')}</span>
            </div>
            <div className="text">
                Delivered so far: <span className="bold">{getTotalDeliveredHives(ranch)} hives</span>
            </div>
            {!bhomesAmount && <RanchDetails ranch={ranch} />}
            <div className="delivery-details">
                {!showDetails && (
                    <button className="details-button" onClick={toggleShowDetails}>
                        Details
                        <FontAwesomeIcon icon={faChevronDown} />
                    </button>
                )}
                {showDetails && (
                    <>
                        <div className="delivery-list">
                            <p className="text bold">Delivery details</p>
                            <ul>
                                {getDeliveryDetails(ranch).map(([date, details]) => (
                                    <li key={date} className="delivery-item">
                                        <span className="text bold">{date}</span>: {details.totalHives} hives (
                                        {details.bhomes} BH & {details.standardHives} hives)
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <button className="details-button" onClick={toggleShowDetails}>
                            Close
                            <FontAwesomeIcon icon={faChevronUp} />
                        </button>
                    </>
                )}
            </div>
        </div>
    );
};

export default RanchInfo;

RanchInfo.propTypes = {
    ranch: PropTypes.shape({
        id: PropTypes.string,
        locations: PropTypes.arrayOf(
            PropTypes.shape({
                bhomeIds: PropTypes.arrayOf(PropTypes.number),
            })
        ),
        createdBy: PropTypes.string,
        totalArea: PropTypes.number,
        country: PropTypes.string,
        totalPlacedStandardHives: PropTypes.number,
        totalPlannedHives: PropTypes.number,
    }),
    bhomesAmount: PropTypes.number,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
};
