import { useState, useCallback, useEffect } from 'react';
import { updateMapBounds } from 'components/reusables/Map/components/Map/utils';
import constants from 'appConstants';
import { useMapContext } from 'components/views/BrokerView/views/Dashboard/context/MapContext';
import useVisibleMarkers from './useVisibleMarkers';

const {
    MAP: { ZOOM },
} = constants;

const removeMapShape = shape => {
    window.google?.maps?.event?.clearInstanceListeners(shape);
    shape.setMap(null);
};

const removePolyShapes = polyShapesRef => {
    polyShapesRef.current.polygons.forEach(removeMapShape);
    polyShapesRef.current.polygons = [];
};

const useMap = ({ selectedRanch, ranches, handleSelectRanch, filteredRanches }) => {
    const { mapRef, createAndAddShape, createShapesForItems, handleTogglePolygon } = useMapContext();
    const [map, setMap] = useState(null);
    const [mapOptions, setMapOptions] = useState({});

    const isBhomeView = mapOptions?.zoom >= ZOOM.LARGE;
    const isRanchPerimeterHidden = mapOptions?.zoom < ZOOM.MIDDLE;

    const { visibleRanches, visibleMarkers, pinMarkers } = useVisibleMarkers({
        ranches: filteredRanches,
        mapOptions,
        isBhomeView,
    });

    const handleLoadMaps = useCallback(
        ({ map }) => {
            setMap(map);
            mapRef.current.map = map;
        },
        [mapRef]
    );

    useEffect(() => {
        if (!map || !visibleRanches.length || isRanchPerimeterHidden) {
            removePolyShapes(mapRef);
            return;
        }
        createShapesForItems({ ranches: visibleRanches, handleSelectCurrentItem: handleSelectRanch });
    }, [
        createAndAddShape,
        map,
        visibleRanches,
        isRanchPerimeterHidden,
        createShapesForItems,
        handleSelectRanch,
        mapRef,
    ]);

    useEffect(() => () => removePolyShapes(mapRef), [mapRef]);

    useEffect(() => {
        selectedRanch
            ? handleSelectRanch({ ranchId: selectedRanch.id })
            : updateMapBounds(
                  map,
                  ranches.flatMap(ranch => ranch.polygons.flat())
              );
        // run only during 1st render
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ranches, map]);

    return {
        map,
        setMap,
        isBhomeView,
        handleMapChange: setMapOptions,
        visibleMarkers,
        pinMarkers,
        handleLoadMaps,
        mapOptions,
        handleTogglePolygon,
    };
};

export default useMap;
