import { useMemo, useEffect, useState } from 'react';
import constants from 'appConstants';

const {
    MAP: {
        ZOOM: { MIDDLE },
    },
} = constants;

const getGooglePoint = timeframe => point =>
    window.google && {
        location: new window.google.maps.LatLng(point.lat, point.lng),
        weight: point[timeframe],
    };

const DEFAULT_GRADIENT = [
    'rgba(255, 0, 0, 0)', // Transparent (0% - no intensity)
    'rgba(255, 0, 0, 1)', // Red (starts at low intensity)
    'rgba(255, 128, 0, 1)', // Orange-Red
    'rgba(255, 255, 0, 1)', // Yellow
    'rgba(128, 255, 0, 1)', // Light Green
    'rgba(0, 255, 0, 1)', // Medium Green
    'rgba(0, 128, 0, 1)', // 100%
];

const METERS_PER_PIXEL_AT_EQUATOR = 156543.03392;

const DEFAULT_POINT_RADIUS_METERS = 100;

const deg2rad = deg => deg * (Math.PI / 180);

const getHeatMapRadius = ({ lat, zoom, pointRadiusMeters }) => {
    // each zoom level doubles the number of pixels
    // At the equator (lat=0°), cos(0)=1
    const meterPerPixel = (METERS_PER_PIXEL_AT_EQUATOR * Math.cos(deg2rad(lat))) / 2 ** zoom;
    const radius = pointRadiusMeters / meterPerPixel;

    return radius;
};

const useHeatMap = ({ map, zoom, data, isHeatMapShown, setIsHeatMapShown }) => {
    const [timeframeIndex, setTimeframeIndex] = useState(0);

    const gradient = data?.settings?.GRADIENT ?? DEFAULT_GRADIENT;
    const pointRadiusMeters = data?.settings?.RADIUS ?? DEFAULT_POINT_RADIUS_METERS;

    const radius = data?.points?.length
        ? getHeatMapRadius({ lat: data?.points?.[0]?.lat, zoom, pointRadiusMeters })
        : 0;

    const googlePoints = useMemo(
        () => map && data?.points?.map(getGooglePoint(data?.timeframes?.[0])),
        [data?.points, map, data?.timeframes]
    );
    const isFeatureAvailable = googlePoints?.length && zoom >= MIDDLE;
    const heatMap = useMemo(
        () =>
            map &&
            !!googlePoints?.length &&
            new window.google.maps.visualization.HeatmapLayer({
                data: googlePoints,
                gradient,
                maxIntensity: 1,
                opacity: 0.7,
                radius,
            }),
        // rerender ony if googlePoints change
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [googlePoints, map, gradient]
    );

    const handleTimeframeChange = index => {
        setTimeframeIndex(index);
        const newData = data?.points?.map(getGooglePoint(data?.timeframes?.[index]));
        heatMap?.setData(newData);
    };

    const handleToggleHeatMap = isShown => {
        setIsHeatMapShown(isShown);
        const newMap = isShown ? map : null;
        heatMap?.setMap(newMap);
    };

    useEffect(() => {
        if (map && heatMap) {
            handleToggleHeatMap(true);
            // set radius inside listener for more smooth transition
            window.google.maps.event.addListener(map, 'zoom_changed', () => {
                const zoom = map.getZoom();
                const radius = getHeatMapRadius({ lat: data?.points?.[0].lat, zoom, pointRadiusMeters });
                heatMap?.set('radius', radius);
            });
        }
        return () => {
            if (map) {
                window.google.maps.event.clearListeners(map, 'zoom_changed');
            }
            if (heatMap) {
                heatMap?.setMap(null);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, heatMap, data?.points]);

    return {
        isHeatMapShown,
        isFeatureAvailable,
        toggleHeatMapShown: handleToggleHeatMap,
        setTimeframeIndex: handleTimeframeChange,
        timeframeIndex,
        timeframes: data?.timeframes,
    };
};

export default useHeatMap;
