import React from 'react';
import { useToggle } from '@beewise/react-utils';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion, faClose } from '@fortawesome/pro-regular-svg-icons';
import MapInfoWrapper from '../../MapInfo/MapInfoWrapper';
import './HeatMapInfoButton.scss';

const InfoIcon = ({ onClick }) => (
    <MapInfoWrapper className="heat-map-info-button">
        <span onClick={onClick} role="presentation">
            <FontAwesomeIcon icon={faCircleQuestion} className="heat-map-info-button" size={16} />
        </span>
    </MapInfoWrapper>
);

InfoIcon.propTypes = {
    onClick: PropTypes.func.isRequired,
};

const InfoText = ({ onClick }) => (
    <MapInfoWrapper className="heat-map-info">
        <span>
            <FontAwesomeIcon icon={faClose} onClick={onClick} />
            Bee visitation is lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit
            interdum, ac aliquet odio mattis.
        </span>
    </MapInfoWrapper>
);

InfoText.propTypes = {
    onClick: PropTypes.func.isRequired,
};

const HeatMapInfoButton = () => {
    const [isInfoShown, toggleInfoShown] = useToggle(false);

    const Component = isInfoShown ? InfoText : InfoIcon;
    return <Component onClick={toggleInfoShown} />;
};

export default HeatMapInfoButton;
